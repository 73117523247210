<template>
  <div>
    <div class="tw-container">
      <!-- 新增收件資訊 -->
      <div class="p-md-2">
        <!-- 上方 -->
        <div class="mb-3">
          <span class="fw-bold tw-text-size18 tw-border-start me-3"
            >新增收件資訊</span
          >
          <button class="tw-btn tw-btn-success" @click="createReceivingInfo">
            新增
          </button>
        </div>
        <!-- 下方 -->
        <div>
          <!-- 收件者姓名 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="RecipientName"
              placeholder="收件者姓名(15字)"
              maxlength="15"
              v-model="createReceipingInfoData.recipient"
            />
            <label for="RecipientName">收件者姓名(15字)</label>
          </div>
          <!-- 收件者電話 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="RecipientPhone"
              placeholder="收件者電話(15字)"
              maxlength="15"
              v-model="createReceipingInfoData.phoneNumber"
            />
            <label for="RecipientPhone">收件者電話(15字)</label>
          </div>
          <!-- 收件者地址 -->
          <div class="row">
            <!-- 縣市 -->
            <div class="col-md-3 p-0 col-6 mb-3">
              <div class="form-floating" v-if="!city.loading">
                <select
                  class="form-select"
                  id="SelectCity"
                  aria-label="選擇縣市"
                  v-model="createReceipingInfoData.city"
                >
                  <option value="">不選擇</option>
                  <option
                    :value="data.City"
                    v-for="data in city.data"
                    :key="data.City"
                  >
                    {{ data.City }}
                  </option>
                </select>
                <label for="SelectCity">選擇縣市</label>
              </div>
              <p class="text-danger" v-else>搜尋中...</p>
            </div>
            <!-- 區域 -->
            <div class="col-md-3 p-0 col-6 mb-3">
              <div class="form-floating">
                <select
                  class="form-select"
                  id="SelectArea"
                  aria-label="選擇區域"
                  v-model="createReceipingInfoData.postalCode"
                >
                  <option value="">不選擇</option>
                  <option
                    :value="data.Zip5"
                    v-for="data in area.data"
                    :key="data.Zip5"
                  >
                    {{ data.Area }}
                  </option>
                </select>
                <label for="SelectArea">選擇區域</label>
              </div>
            </div>
            <!-- 街道 -->
            <div class="col-md-6 p-0 col-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="Address"
                  placeholder="街道名(30字)"
                  maxlength="30"
                  v-model="createReceipingInfoData.address"
                />
                <label for="Address">街道名(30字)</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <!-- 收件資訊列表 -->
      <div class="p-md-2">
        <!-- 上方 -->
        <div class="mb-3">
          <p class="fw-bold tw-border-start tw-text-size18">收件資訊列表</p>
        </div>
        <!-- 下方 -->
        <div class="table-responsive">
          <table class="table table-hover" v-if="receivingInfoList.length > 0">
            <thead>
              <tr>
                <th style="min-width: 150px">新增者</th>
                <th style="min-width: 150px">電話</th>
                <th style="min-width: 150px">姓名</th>
                <th style="min-width: 200px">地址</th>
                <th style="min-width: 150px">設定</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in receivingInfoList" :key="data.id">
                <th scope="row">{{ data.creatorName }}</th>
                <td>{{ data.phoneNumber }}</td>
                <td>{{ data.recipient }}</td>
                <td style="min-width: 100px">
                  {{
                    data.postalCodeDetail.City +
                    data.postalCodeDetail.Area +
                    data.address
                  }}
                </td>
                <td style="min-width: 115px">
                  <template
                    v-if="
                      participantId > 0 &&
                      (currentPage !== 'S_ReceiptInfos' ||
                        currentPage !== 'C_ReceiptInfos')
                    "
                  >
                    <div v-if="data.canEdit">
                      <button
                        class="tw-btn tw-btn-secondary me-2"
                        @click="showModal('editReceivingInfo', data)"
                      >
                        編輯
                      </button>
                      <button
                        class="tw-btn tw-btn-danger"
                        @click="showModal('delReceivingInfo', data)"
                      >
                        刪除
                      </button>
                    </div>
                    <div class="text-danger fw-bolder" v-else>X</div>
                  </template>
                  <template v-else>
                    <button
                      class="tw-btn tw-btn-secondary me-2"
                      @click="showModal('editReceivingInfo', data)"
                    >
                      編輯
                    </button>
                    <button
                      class="tw-btn tw-btn-danger"
                      @click="showModal('delReceivingInfo', data)"
                    >
                      刪除
                    </button>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="text-danger fw-bold" v-else>目前尚無任何收件資訊!</p>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯收件資訊的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editReceivingInfoModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">編輯收件資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 收件者姓名 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="RecipientName"
              placeholder="收件者姓名(15字)"
              maxlength="15"
              v-model="editReceipingInfoData.recipient"
            />
            <label for="RecipientName">收件者姓名(15字)</label>
          </div>
          <!-- 收件者電話 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="RecipientPhone"
              placeholder="收件者電話(15字)"
              maxlength="15"
              v-model="editReceipingInfoData.phoneNumber"
            />
            <label for="RecipientPhone">收件者電話(15字)</label>
          </div>
          <!-- 收件者地址 -->
          <div class="row">
            <!-- 縣市 -->
            <div class="col-md-3 p-0 col-6 mb-3">
              <div class="form-floating" v-if="!city.loading">
                <select
                  class="form-select"
                  id="SelectCity"
                  aria-label="選擇縣市"
                  v-model="editReceipingInfoData.city"
                  @click="switchCleanPostalCode"
                >
                  <option value="">不選擇</option>
                  <option
                    :value="data.City"
                    v-for="data in city.data"
                    :key="data.City"
                  >
                    {{ data.City }}
                  </option>
                </select>
                <label for="SelectCity">選擇縣市</label>
              </div>
              <p class="text-danger" v-else>搜尋中...</p>
            </div>
            <!-- 區域 -->
            <div class="col-md-3 p-0 col-6 mb-3">
              <div class="form-floating">
                <select
                  class="form-select"
                  id="SelectArea"
                  aria-label="選擇區域"
                  v-model="editReceipingInfoData.postalCode"
                >
                  <option value="">不選擇</option>
                  <option
                    :value="data.Zip5"
                    v-for="data in area.data"
                    :key="data.Zip5"
                  >
                    {{ data.Area }}
                  </option>
                </select>
                <label for="SelectArea">選擇區域</label>
              </div>
            </div>
            <!-- 街道 -->
            <div class="col-md-6 p-0 col-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="Address"
                  placeholder="街道名(30字)"
                  maxlength="30"
                  v-model="editReceipingInfoData.address"
                />
                <label for="Address">街道名(30字)</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="editReceipingInfo"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除收件資訊的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delReceivingInfoModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bold
            "
          >
            刪除收件資訊
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">確認刪除此收件資訊 ?</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="delReceipingInfo"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// API
import { GetCityArea } from '@/methods/API/getCityArea'
import { parseToken, sortoutParseTokenResult } from '@/methods/API/parseToken'
import { parseTokenToGetTheParticipant } from '@/methods/API/parseTokenToGetTheParticipant.js'

export default {
  props: {
    participantId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // modal
      editReceivingInfoModal: {},
      delReceivingInfoModal: {},
      // data
      storeId: 0,
      serverToken: '',
      // 目前頁面 (分辨 user，customer 的 receivingInfo)
      currentPage: '',
      // 新增收件資訊
      createReceipingInfoData: {
        recipient: '',
        phoneNumber: '',
        city: '',
        postalCode: '',
        address: ''
      },
      // 編輯收件資訊
      editReceipingInfoData: {
        id: 0,
        recipient: '',
        phoneNumber: '',
        city: '',
        postalCode: '',
        address: ''
      },
      clearPostalCode: false,
      receivingInfoList: [],
    }
  },
  // city: any[]; area: any[]; 來自 GetCityArea
  mixins: [GetCityArea],
  mounted() {
    this.createModals(['editReceivingInfoModal', 'delReceivingInfoModal'])
  },
  watch: {
    participantId: {
      handler(val, oldVal) {
        console.log(val, oldVal)
        // 初始化
        this.initialization()
        // 取得城市區域
        this.getCity()
        if (val === -1) {
          // 只抓 user receiveingInfo
          this.getUserInfo()
        } else if (val > 0) {
          // 抓 user customer
          this.getParticipantId()
        }
      },
      immediate: true
    },
    // 選擇縣市 (新增)
    'createReceipingInfoData.city'(val) {
      this.createReceipingInfoData.postalCode = ''
      this.getArea(val)
    },
    // 電話號碼格式 (新增)
    'createReceipingInfoData.phoneNumber'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.createReceipingInfoData.phoneNumber = val.replace(/[^\d]/g, "");
      }
    },
    // 選擇縣市 (編輯)
    'editReceipingInfoData.city'(val, oldVal) {
      if (this.clearPostalCode) {
        this.editReceipingInfoData.postalCode = ''
      }
      this.getArea(val)
      this.clearPostalCode = false
    },
    // 電話號碼格式 (編輯)
    'editReceipingInfoData.phoneNumber'(val) {
      if ( !/^[0-9]*$/.test(val) ) {
        this.editReceipingInfoData.phoneNumber = val.replace(/[^\d]/g, "");
      }
    },
  },
  methods: {
    // 開啟 modal
    showModal(status, data) {
      if (status === 'editReceivingInfo') {
        // 編輯收件資訊
        this.clearPostalCode = false
        this.editReceipingInfoData = {
          id: data.id,
          recipient: data.recipient,
          phoneNumber: data.phoneNumber,
          city: data.postalCodeDetail.City,
          postalCode: data.postalCodeDetail.Zip5,
          address: data.address,
          status: data.status
        }
        this.editReceivingInfoModal.show()
      } else if (status === 'delReceivingInfo') {
        // 刪除收件資訊
        this.editReceipingInfoData = {
          id: data.id,
          recipient: data.recipient,
          phoneNumber: data.phoneNumber,
          city: data.postalCodeDetail.City,
          postalCode: data.postalCodeDetail.Zip5,
          address: data.address,
          status: data.status
        }
        this.delReceivingInfoModal.show()
      }
    },
    // 初始化
    initialization() {
      this.storeId = this.$route.params.storeId
      this.serverToken = this.$methods.getCookie('serverToken')
      // 判斷目前路由位置
      this.currentPage = this.$route.name
      this.createReceipingInfoData = {
        recipient: '',
        phoneNumber: '',
        city: '',
        postalCode: '',
        address: ''
      }
    },
    // 取得使用者資訊 (user)
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getReceipingInfoList(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得收件列表 (user)
    getReceipingInfoList(user) {
      const vm = this
      const getReceipingInfoListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: user.ids,
          methods: '{getUserReceivingInfos{getPostalCodeDetail,getOwner},getReceivingInfos{getPostalCodeDetail,getCreator{getUser},getOwner{getUser}}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getReceipingInfoListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const userInfo = res.data[0].objects[0]
            vm.sortOutData(userInfo)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 取得目前帳號的 participant
    getParticipantId() {
      const result = parseTokenToGetTheParticipant(this.storeId, this.serverToken)
      result.then(res => {
        console.log(res)
        if (res.code === '200') {
          const participantInfo = res.data
          this.getParticipantInfo(participantInfo)
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得participant資訊 (customer)
    getParticipantInfo(currentUserParticipant) {
      this.$methods.switchLoading('show')
      const vm = this
      const getParticipantInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 13,
          ids: [this.participantId],
          methods: '{getReceivingInfos{getPostalCodeDetail,getCreator{getUser},getOwner{getUser}}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getParticipantInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const participantInfo = res.data[0].objects[0]
            vm.sortOutData(participantInfo, currentUserParticipant)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 整理資料
    sortOutData(info, currentUserParticipant) {
      setTimeout(() => {
        this.receivingInfoList = []
        if (this.currentPage === 'S_ReceiptInfos' || this.currentPage === 'C_ReceiptInfos') {
          console.log('user')
          // 若在個人頁面 > 收件資訊，就取得 userReceivingInfos
          info.userReceivingInfos.forEach(item => {
            item.creatorName = item.owner.name
            item.canEdit = true
            item.status = 'user'
            this.receivingInfoList.push(item)
          });
        } else if (this.currentPage === 'C_Overview') {
          console.log('C_Overview')
          // 賣場總覽
          info.receivingInfos.forEach(item => {
            if (item.creator) {
              item.canEdit = this.participantId === item.creatorId
              item.creatorName = item.creator.user.name
              item.status = 'customer'
            } else {
              item.canEdit = true
              item.creatorName = item.owner.name
              item.status = 'user'
            }
            this.receivingInfoList.push(item)
          })
        } else {
          console.log('participantDetail')
          // 顧客詳細 > 收件資訊 (canEdit 要判斷的 participantId 不是顧客的而是目前帳號的 participantId)
          console.log(currentUserParticipant)
          info.receivingInfos.forEach(item => {
            if (item.creator) {
              item.canEdit = currentUserParticipant.id === item.creatorId
              item.creatorName = item.creator.user.name
              item.status = 'customer'
            } else {
              item.canEdit = currentUserParticipant.userId === item.ownerId
              item.creatorName = item.owner.name
              item.status = 'user'
            }
            this.receivingInfoList.push(item)
          })
        }
        this.$methods.switchLoading('hide')
      }, 1)
    },
    // 清除 postalcode
    switchCleanPostalCode() {
      this.clearPostalCode = true
    },
    // 判斷更新時要取哪個 function
    checkUpdateFunction() {
      if (this.currentPage === 'S_ReceiptInfos' || this.currentPage === 'C_ReceiptInfos') {
        this.getUserInfo()
      } else {
        this.getParticipantId()
      }
    },
    // 新增收件資訊
    createReceivingInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const createReceivingInfoApi = `${process.env.VUE_APP_API}/receivingInfo/${(this.currentPage === 'S_ReceiptInfos' || this.currentPage === 'C_ReceiptInfos') ? 'createUserReceivingInfo' : 'createCustomerReceivingInfo'}`
      const header = {
        authorization: this.serverToken
      }
      let object = {
        recipient: this.createReceipingInfoData.recipient ? this.createReceipingInfoData.recipient : null,
        phoneNumber: this.createReceipingInfoData.phoneNumber ? this.createReceipingInfoData.phoneNumber : null,
        postalCode: this.createReceipingInfoData.postalCode ? this.createReceipingInfoData.postalCode : null,
        address: this.createReceipingInfoData.address ? this.createReceipingInfoData.address : null
      }
      if (this.currentPage !== 'S_ReceiptInfos' && this.currentPage !== 'C_ReceiptInfos') object.ownerId = this.participantId
      const data = [object]
      $.ajax({
        type: 'POST',
        async: true,
        url: createReceivingInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.createReceipingInfoData = {
              recipient: '',
              phoneNumber: '',
              city: '',
              postalCode: '',
              address: ''
            }
            vm.checkUpdateFunction()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 編輯收件資訊 (user)
    editReceipingInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const editReceipingInfoApi = `${process.env.VUE_APP_API}/receivingInfo/${this.editReceipingInfoData.status === 'user' ? 'updateUserReceivingInfo' : 'updateCustomerReceivingInfo'}`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          id: this.editReceipingInfoData.id,
          recipient: this.editReceipingInfoData.recipient ? this.editReceipingInfoData.recipient : null,
          phoneNumber: this.editReceipingInfoData.phoneNumber ? this.editReceipingInfoData.phoneNumber : null,
          postalCode: this.editReceipingInfoData.postalCode ? this.editReceipingInfoData.postalCode : null,
          address: this.editReceipingInfoData.address ? this.editReceipingInfoData.address : null
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: editReceipingInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.checkUpdateFunction()
            vm.editReceivingInfoModal.hide()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 刪除收件資訊 (user)
    delReceipingInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const delReceipingInfoApi = `${process.env.VUE_APP_API}/receivingInfo/${this.editReceipingInfoData.status === 'user' ? 'deleteUserReceivingInfo' : 'deleteCustomerReceivingInfo'}`
      const header = {
        authorization: this.serverToken
      }
      const data = new FormData()
      data.append('infoIds', this.editReceipingInfoData.id)
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delReceipingInfoApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.delReceivingInfoModal.hide()
            vm.checkUpdateFunction()
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>